/* Component: Advisor Reviews */

/* Cleans the Swiper pagination styles as grey dots without absolute positioning and no vertical margins */

.advisor-reviews_foraSwiper__Prd9W swiper-container::part(pagination) {
  --swiper-pagination-bullet-size: 8px;
  --swiper-pagination-bullet-width: 8px;
  --swiper-pagination-bullet-height: 8px;
  --swiper-pagination-bullet-inactive-opacity: 1;
  --swiper-pagination-bullet-opacity: 1;
  --swiper-pagination-bullet-horizontal-gap: 2px;
  position: static;
  display: flex;
  justify-content: center;
}

.advisor-reviews_foraSwiper__Prd9W swiper-container::part(bullet-active) {
  --tw-bg-opacity: 1;
  background-color: rgba(130, 115, 99, var(--tw-bg-opacity));
}

@media (min-width: 768px) {

  .advisor-reviews_foraSwiper__Prd9W swiper-container::part(bullet-active) {
    display: none;
  }
}

.advisor-reviews_foraSwiper__Prd9W swiper-container::part(bullet) {
  --tw-bg-opacity: 1;
  background-color: rgba(189, 177, 164, var(--tw-bg-opacity));
}

@media (min-width: 768px) {

  .advisor-reviews_foraSwiper__Prd9W swiper-container::part(bullet) {
    display: none;
  }
}

/* Styles Swiper navigation using icon-arrow-circle-left-black.svg and icon-arrow-circle-right-black.svg */

.advisor-reviews_foraSwiper__Prd9W swiper-container::part(button-prev),
.advisor-reviews_foraSwiper__Prd9W swiper-container::part(button-next) {
  --swiper-navigation-size: 24px;
  --swiper-navigation-top-offset: calc(50% - 12px);
  --swiper-navigation-sides-offset: 0px;
  height: 1.5rem;
  width: 1.5rem;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.advisor-reviews_foraSwiper__Prd9W swiper-container::part(button-prev) {
  background-image: url('https://media.foratravel.com/image/upload/v1727798321/icon-arrow-circle-left-black_mxyonz.svg');
}

.advisor-reviews_foraSwiper__Prd9W swiper-container::part(button-prev) .advisor-reviews_swiper-button-disabled__KtuAN {
  opacity: 0.35;
}

.advisor-reviews_foraSwiper__Prd9W swiper-container::part(button-next) {
  background-image: url('https://media.foratravel.com/image/upload/v1727798508/icon-arrow-circle-right-black_thgnhs.svg');
}

.advisor-reviews_foraSwiper__Prd9W swiper-container::part(button-next) .advisor-reviews_swiper-button-disabled__KtuAN {
  opacity: 0.35;
}

@media (max-width: 768px) {
  .advisor-reviews_foraSwiper__Prd9W swiper-container::part(button-prev), .advisor-reviews_foraSwiper__Prd9W swiper-container::part(button-next) {
    display: none;
  }
}

.advisor-reviews_foraSwiper__Prd9W swiper-slide {
  height: auto;
}

.advisor-reviews_foraSwiper__Prd9W swiper-container::part(pagination) {
  margin-top: 2rem;
}

@media (min-width: 1024px) {

  .advisor-reviews_foraSwiper__Prd9W swiper-container::part(pagination) {
    margin-top: 1.5rem;
  }
}

